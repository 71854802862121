$COL1: #000000; //black
$COLFLAT1: #000000; //black
$COL2: #ffffff; //white
$COLFLAT2: #ffffff; //white
$COL3: #f8f8f8; //light grey
$COLFLAT3: #f8f8f8; //light grey
$COL4: #4b4d49; //dark grey
$COLFLAT4: #4b4d49; //dark grey
$COL5: #e3e4e3; //mid grey
$COLFLAT5: #e3e4e3; //mid grey
$COL6: #acb22d; //primary
$COLFLAT6: #acb22d; //primary
$COL7: rgba(0,0,0,0.302); //transparent black
$COLFLAT7: #595959; //transparent black
$COL8: #b2b2b2; //cust grey
$COLFLAT8: #b2b2b2; //cust grey
$COL9: #e8e8e8; //Light Grey2
$COLFLAT9: #e8e8e8; //Light Grey2
$COL10: #fa9c00; //Orange
$COLFLAT10: #fa9c00; //Orange
$COL11: #fa7d00; //Dark Orange
$COLFLAT11: #fa7d00; //Dark Orange
$COL12: rgba(0,0,0,0); //
$COLFLAT12: #808080; //
$COL13: #abaca8; //grey
$COLFLAT13: #abaca8; //grey
$COL14: #4b4d49; //
$COLFLAT14: #4b4d49; //
$COL15: #acb22d; //
$COLFLAT15: #acb22d; //
$COL16: #ffffff; //
$COLFLAT16: #ffffff; //
$COL17: #acb22d; //
$COLFLAT17: #acb22d; //
$COL18: #acb22d; //
$COLFLAT18: #acb22d; //
$COL19: #acb22d; //
$COLFLAT19: #acb22d; //
$COL20: #acb22d; //
$COLFLAT20: #acb22d; //
$COL21: #acb22d; //
$COLFLAT21: #acb22d; //
$COL22: #acb22d; //
$COLFLAT22: #acb22d; //
$COL23: #acb22d; //
$COLFLAT23: #acb22d; //
$COL24: #4b4d49; //
$COLFLAT24: #4b4d49; //
$COL25: #acb22d; //
$COLFLAT25: #acb22d; //
$COL26: rgba(172,178,45,0.102); //green transparent
$COLFLAT26: #848578; //green transparent
$COL27: #dddddd; //grey
$COLFLAT27: #dddddd; //grey
/* WARNING: Unable to find output for 1:base style (type=base)*/
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
