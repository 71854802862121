.report-file-dropzone {
  border: 2px dashed #ccc;
  padding: 40px 20px 30px 20px;
  text-align: center;
  background-color: white;
  cursor: pointer;
}
.report-file-dropzone.dropzone-hover {
  background: #e8e8e8;
  border-color: #2b8ed0;
}

.report-file-dropzone p {
  font-size: 1.3em;
}
.report-file-dropzone form {
  height: 0; margin: 0; padding: 0;
}

.file-upload-progress {
  position: relative;
  border-radius: 2px;
  border: 1px solid #2b8ed0;
  height: 2em;
}

.file-upload-progress-bar {
  position: absolute;
  height: 100%;
  width: 0;
  background-color: #a0d8ff;
}
.file-upload-progress-label {
  line-height: 1.2em;
  padding: 0.25em;
  position: absolute;
  height: 100%;
  width: 100%;
}
.medicalreport-metadata {
  li:first-child {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  li:last-child {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
}

.physician, .medicalreport-metadata {
  border: 1px solid #CCC;
  padding: 10px;
  background: #e8e8e8;
  min-height: 262px;
  p {
    font-size: 14px;
    line-height: 2;
  }
}
