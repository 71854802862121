.me-owrap.me-doc {

  .site-header {
    .me-block.me-Image{
      img{
        max-width: 385px;
        width: 100%;
        padding: 0 15px;
      }
    }
    .me-Menu.me-block {
      & > ul {
        text-align: right;
        & > li {
          display: inline-block;
          a {
            background-color: rgba(0, 0, 0, 0);
            color: #333333;
          }
        }
      }
    }
  }
  .strip{
    & > .me-block > .overlay{
      background-position: center;
    }
  }
  .home-blocks img {
    width: 50%;
  }

  .home-carousel,.me-owrap.me-ContentFilter2{
    .me-block.me-Panel2{
      figure{padding:0;}
      h4{
        color: #4b4d49;
        font-size: 16px;
        text-align: center;
      }
      &:hover{
        background-color: rgba(0,0,0,0.4);
        h4{
          color: white;
        }
      }
    }
  }
  .home-carousel{
    .me-block.me-Hamle{
      h4{min-height: 44px;}
    }
  }
  .home-services-button{
    a{
      text-transform: uppercase;
      padding-left: 30px;
      padding-right: 30px;
    }
    span{padding:0;}
  }

  .news-list{
    li.item{
      padding: 30px 40px;
      @media screen and (max-width: 640px){
        [data-align="right"]{text-align:center;}
      }
    }
    li.item:nth-child(odd){
      background-color: #e8e8e8;
    }
    li.item:nth-child(even) {
      background-color: #f8f8f8;
    }
  }

  .site-subfooter{
    hr{
      background-color: #4b4d49;
      border-color: #4b4d49;
    }
  }

  .me-block.me-FilterContent{
    & > ul{margin:0;}
  }

  .me-block.me-Form{
    .form-heading{
      background-color:#acb22d;
      color: white;
      padding: 10px 0;
      margin-top: 50px;
      &.first {margin-top:0;}
    }
    input:not([type="submit"]), select, textarea{
      border-radius: 3px;
      border: 1px solid #acb22d;
      color: #000;
      background-color: rgba(172, 178, 45,0.1);
    }
    ::-webkit-input-placeholder {
      color: #4b4d49;
    }

    :-moz-placeholder { /* Firefox 18- */
      color: #4b4d49;
    }

    ::-moz-placeholder {  /* Firefox 19+ */
      color: #4b4d49;
    }

    :-ms-input-placeholder {
      color: #4b4d49;
    }
    input[type="submit"]{
      background-color: #acb22d;
      padding: 10px 30px;
    }
  }

  .me-block.me-FilterContent {
    .me-block.me-Hamle a.download {
      background: #B2B2B2;
      padding: 10px 30px;
      color: #FFF;
      margin-top:20px;
    }
  }
  .me-PayBasePayment,  .me-PayStripePayment, .me-CartAccount,
  .me-CartAddress, .me-CartPromoCode{
    .me-owrap.me-doc{background-color: transparent;}
    input:not([type="submit"]), select{
      border: 1px solid #fff;
      border-radius: 3px;
      background-color: rgba(255,255,255,0.3);
      color:white;
    }
    option{background: rgba(0,0,0,0.5);}
  }

  .report-wrap{
    @media screen and (max-width: 980px) {
      padding: 0 20px;
    }
  }

  .site-report{
    figure{
      margin: 1rem;
      text-align: center;
    }
    .column.item:nth-child(odd) {
      background-color: #F2F2F2;
    }
    .column.item:nth-child(even){
      background-color: #FFFFFF;
    }

    @media screen and (max-width: 640px) {
      li{padding-bottom  : 2rem;}
      img{max-width: 150px;}
      p{text-align: center;}
    }
  }

  .download{
    @media screen and (max-width: 980px){
      display: block;
      max-width: 225px;
      margin: 0 auto;
    }
  }
  .right-up{
    .me-BaseButton[data-align='left']{
      @media screen and (max-width: 640px) {
        text-align: center;
      }
    }
  }
  .me-CartReportMemoInput textarea {
    min-height: 100px;
  }
  .site-footer {
    padding-bottom: 0 !important;
    .me-block.me-Panel2{
      .me-block.me-CKEditor {
        ul{
          margin: 0;
          list-style: none;
          li{
            color:white;
            display:inline-block;
            vertical-align: middle;
            width: 49%;
          }
        }
        font-size: 14px;
        a{
          color: white;
        }
        .phone{
          font-size: 18px;
        }
      }
    }
  }

  @media screen and (max-width: 980px) {
    .me-owrap.me-Faq > .me-block .faq-head h4{
      font-size: 14px;
    }
  }
}