.downarrow {
  border-bottom: 40px solid #acb22d;
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
  bottom: 0;
  cursor: pointer;
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 99;
  .wrap {
    color: #fff;
    font-size: 10px;
    left: 50%;
    line-height: 1;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 14px;
    transform: translate(-50%);
    i {
      font-size: 20px;
    }
  }
}

.news-list {
  & > .content-body {
    & > .column.item {
      &:nth-child(odd) {
        background-color: #e8e8e8;
      }
      &:nth-child(even) {
        background-color: #f8f8f8;
      }
    }
  }
}

.home-login {
  input:not([type='submit']) {
    border: 0;
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.3);
    max-width: 225px;
    margin: 10px auto;
    color: white;
  }
  button[type='submit'] {
    max-width: 225px;
    margin: 10px auto 0;
  }
  ::-webkit-input-placeholder {
    color: white;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: white;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
  }

  :-ms-input-placeholder {
    color: white;
  }
}

.site-bio {
  em.dent {
    font-style: normal;
    padding: 0;
    margin-left: -8px;
    color: #4b4d49;
  }
}

.home-carousel {
  .slick-slide {
    display: flex;
    flex: 1;
    & > div {
      display: flex;
    }
    .item.column {
      padding: 0 5px !important;
      display: flex !important;
    }
  }
}

@media #{$small-up} {
  .small-center {
    text-align: center;
  }
}

@media #{$large-up} {
  .large-left {
    text-align: left;
  }
}

.custom-checkbox {
  input[type='checkbox'] {
    transform: scale(1.5);
  }
}
